.section-content-wrapper {

}

.section-story  .section-content{
    display: grid;
    padding: 78px 0px 46px 0px;
    grid-template-columns: 11.1% 37.8% 11.1% auto;
    grid-template-rows: auto 1fr 0.25fr auto;
}

.section-story .section-nav{
    grid-column: 1;
    grid-row: 1;
    margin-top: -0.9ch;
}

.section-story  .heading{
    grid-column: 2;
    grid-row: 1;
    max-width: 200px;
    margin-top: -0.45em;
} 

.section-story  .hero-image{
    grid-row: 1 / span 2;
    grid-column: 4;
    background-size: 100% auto;
    background-image: url("/story.jpg");
    background-repeat: no-repeat;
} 

.section-story .text-group {
    grid-row: 2;
    grid-column: 2;
    /* margin-bottom: 87px; */
}

.section-story .wines-button {
    grid-row: 4;
    grid-column: 2;
}

.section-story .artifact {
    position: absolute; 
    opacity: 0.4;
    width: 280px; 
    height: 280px; 
    left: 50%;
    top: 40%;
    overflow: visible;
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    
    .section-story  .section-content{
        padding: 60px 0px 75px 0px;
        grid-template-columns: 10.45% 8% 24.5% 7.05% 7.05% 24.5% 18.45%;
        grid-template-rows: auto 3fr 1fr auto
    }

    .section-story .section-nav{
        /* margin-left: 77px; */
        justify-self: start;
        grid-row: 1;
        grid-column: 2;
    }

    .section-story .heading{ 
        width: auto;
        grid-column: 3;
	    margin-bottom: 30px;
    }
    
    .section-story .text-group {
        grid-column:3 / span 2;
        width: auto;
    }

    .section-story  .hero-image{
        grid-column: 6 / span 2;
        background-position: calc(70% + 250px) 0px;
        background-size: auto 492px ;  
    } 

    .section-story .artifact {
        width: 270px;
        height: 270px;
        left: 60%;
        top:50%;
    }
    
    .section-story .wines-button {
        grid-column: 3;
    }
}


@media only screen and (max-height: 400px) {
    .section-story .wines-button {
        display: none;
    }
}

/* Mobile */
@media only screen and (max-width: 530px) {

    .section-story  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-story .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 100px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-story .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-story .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-story .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-story .wines-button {
        display: none;
    }



    .section-story .artifact {
        width: 150px;
        height: 150px;
        top: 250px;
        left:50%;
    }
}
