
.section-acquire{
    background-color: rgb(18,18,18);
    background-repeat: no-repeat;
    background-position: 50% 0px;
    background-size: auto 100%;
    background-image:url("/acquire.jpg");
}

.section-acquire .section-content-wrapper{
    background-color: transparent;
}


.section-acquire  .section-content{
    height: 100vh;
    display: grid;
    padding: 78px 0px 46px 0px;
    grid-template-columns: 11.1% 37.8% 11.1% auto;
    grid-template-rows: auto 1fr 0.25fr 2fr;
}

.section-acquire .section-nav{
    grid-column: 1;
    grid-row: 1;
    margin-top: -0.9ch;
}

.section-acquire  .heading{
    grid-column: 2;
    grid-row: 1;
    max-width: 200px;
    margin-top: -0.45em;
} 

/* .section-acquire  .hero-image{
    grid-row: 1 / span 2;
    grid-column: 4;
    background-size: 100% auto;
    background-image: url("acquire.jpg");
    background-repeat: no-repeat;
}  */

.section-acquire .text-group {
    grid-row: 2;
    grid-column: 2;
    /* margin-bottom: 87px; */
}

.section-acquire .wines-button {
    grid-row: 4;
    grid-column: 2;
}

.section-acquire .artifact {
    position: relative; 
    grid-row: 1 / span 3; 
    grid-column: 1 / span 4; 
    opacity: 48%;
    width: 200px; 
    height: 200px; 
    left: 50%;
    top: 40%;
    overflow: visible;
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    
    .section-acquire  .section-content{
        padding: 60px 0px 75px 0px;
        grid-template-columns: 10.45% 8% 24.5% 7.05% 7.05% 24.5% 18.45%;
        grid-template-rows: auto 3fr 1fr auto
    }

    .section-acquire .section-nav{
        /* margin-left: 77px; */
        justify-self: start;
        grid-row: 1;
        grid-column: 2;
    }

    .section-acquire .heading{ 
        width: auto;
        grid-column: 3;
	    margin-bottom: 30px;
    }
    
    .section-acquire .text-group {
        grid-column:3 / span 2;
        width: auto;
    }

    .section-acquire  .hero-image{
        grid-column: 6 / span 2;
        background-position: calc(70% + 250px) 0px;
        background-size: auto 492px ;  
    } 

    .section-acquire .artifact {
        grid-column: 6 / span 2;
        grid-row: 2;
        left:0px;
        opacity: 40%;
        top: 250px;
        width: 100%;
        height: 270px;

    }
    
    .section-acquire .wines-button {
        grid-column: 3;
    }
}


@media only screen and (max-height: 400px) {
    .section-acquire .wines-button {
        display: none;
    }
}

/* Mobile */
@media only screen and (max-width: 530px) {
    .section-content-wrapper {
	/* height: 100vh; */
     }
    .section-acquire  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-acquire .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 100px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-acquire .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-acquire .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-acquire .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-acquire .wines-button {
        display: none;
    }



    .section-acquire .artifact {
        grid-column: 3;
        width: 60%;
        margin-top: -180px;
        left: 60px;

    }
}
