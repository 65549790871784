#languageButton {
    position: relative;
    grid-column: 3;
    grid-row: 2;
    height: auto;
    border-radius: 50%;
    background-color: black;
    color: white;
    width: auto;
    bottom: -50%;
}

#emailGroup {
    grid-column: 1;
}

#phoneGroup {
    grid-column: 2;
}

#addressGroup {
    grid-column: 3;
}

#openGroup {
    grid-column: 4;
}

#info {
    justify-self: center;
    grid-row: 2;
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 80%;
    align-self: center;
}

.footerLabel {
    text-transform: uppercase;
    font-weight: 600;
}

.infoGroup {
    display: grid;
    row-gap: 1.5em;
    flex-direction: column;
}

.infoGroup label {
    justify-self: center;
    align-self: center;
}

#artifact {
    position: absolute; 
    opacity: 0.4;
    /* bottom: calc(-50% - 100px); */
    top: -37px;
    opacity: 0.4;
    height: 400px;
    width: 400px;
    left: 50%; 
    overflow: visible;
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transform: scaleX(-1);
}