.quickMenu{
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    width: 57.5vw;
    height: 100vh;
    opacity: 0;
    background-color: black;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.801);
    transform: translate(70vw, 0);
    transition-property: transform opacity;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;

}
.navItemsWrapper {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: var(--desktop-padding);
}

.quickMenuExpanded{
    opacity: 100;
    composes: quickMenu;
    transform: translate(0vw, 0);
}



.navItems {
    margin-top: 80px;
}


.navItems a {
    display: flex;
    font-size: 16px;
    font-family: "Open Sans";
    justify-content: space-between;
    color: white;
    margin-top: 2rem;
    list-style: none;
    text-decoration: none;
}

.quickMenuLabel {
    text-transform: uppercase;
    display: block;
    color: white;
    font-size: 13px;
    margin-left: 2.2rem;
    margin-top: 1.5rem;
    transform: rotate(-90deg);
    transform-origin: left;
}

.closeButton {
    background-image: url("close.svg");
    background-repeat: no-repeat;
    height: 61px;
    width: 61px;
    top: 0;
    right: 0;
    margin-top: 12px;
    margin-right: 0px;
    cursor: pointer;
    position: absolute;
 } 





/* .navItems a > .active {
    font-style: italic;
} */

/* .navItemExpanded > .active li{
    list-style-image: url('minus.svg');
} */




/* .nav-button-expanded:hover {
    cursor: pointer;
} */

/* .quick-menu {
    display: none;
} */

/* .quick-menu-expanded {
    text-transform: uppercase;
    display: block;
    color: white;
    font-size: 13px;
    margin-left: 2.2rem;
    margin-top: 1.5rem;
    transform: rotate(-90deg);
    transform-origin: left;
} */

    /* .quick-menu {
        display: block;
        color: white;
        text-transform: uppercase;
        margin-right: 1rem;
        font-weight: 600;
        font-size:10pt;
    } */

/* 
    @media only screen and (max-height: 488px) {  

        .nav-item-expanded{
            margin-top: 20px;
            
        }
        .quick-menu-expanded{
            transform: none;
            display: none;
            
        }
    
        .nav-button-expanded{
            position: fixed;
            right: 0.5rem;
        }
    } */