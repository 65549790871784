:root {
  --desktop-max-width: 1324px;
  --desktop-max-height:800px;
  --desktop-padding: 0 22px;
}

.section-content-wrapper {
  background-color: white;
  max-width: var(--desktop-max-width);
  margin-left: auto;
  margin-right: auto;

}

.copy-text {
  margin-bottom: 20px;
  margin-top: 0px;
}

.section-content {
  overflow-x: hidden;
  display: grid;
  position: relative;
}


.section-nav {
  margin-block-start: 0px;
  margin-block-end: 0px; 
  text-transform: uppercase;
  letter-spacing: 0.7pt;
  text-orientation: mixed;
  writing-mode: vertical-rl;

  align-self: start;
  justify-self: center;
}

.flippedY {
  transform: scaleY(-1) scaleX(-1);
  transform-origin: center center;
}


.dark {
  color: white;
}


@media only screen and (orientation: portrait) and (max-width: 890px) {  
  .section-content-wrapper {
     /* height: 100vh; */
  }

  /* .section-content {
    height: 100vh;
  } */
}


@media only screen and (max-width: 890px) {  
  :root {
    --desktop-padding: 0 40px;
  }
}
