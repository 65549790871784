@keyframes fadein {
    from {opacity:0%;}
    to {opacity:100%;}
  }

.panel {
    display: none;
    animation-name: fadein;
    animation-duration: 0.6s;
}

.panel-expanded {
   display: block;
}


.showMoreButton {
    background-color: transparent;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    box-shadow: none;
    border-color: black;
    width: auto;
    height: auto;
    padding: 0px;
    letter-spacing: 1.4px;
    font-size: 10px;
    margin: 20px 0px;
    opacity: 100;
    transition: opacity 0.5s ease-in-out;
    max-height: none;
    cursor: pointer;
}

.showMoreButton-expanded {
   display: none;
}
