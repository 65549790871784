@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap";


body {
  /* background-color: rgb(44,44,44); */
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  background-color: rgb(16,16,16);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  scroll-behavior: smooth;
  /* scroll-snap-type: y proximity; */
  /* -webkit-overflow-scrolling: touch; */
}
/* */
@media only screen and (max-width: 890px) {
  /* body {
    scroll-snap-type: proximity;
  } */

} 

@media only screen and (max-width: 500px) {
  /*body {
    scroll-snap-type: mandatory;
  }*/

} 

.linkButton {
  text-align: center;
  text-decoration: none;
  background-color: white;
  border: none;
  font-size: 9pt;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0 0px 20px 0 rgba(0,0,0,0.2);
  width: 190px;
  height: 31px;
  margin: 5px;
}

button {
  background-color: white;
  border: none;
  font-size: 9pt;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0 0px 20px 0 rgba(0,0,0,0.2);
  width: 190px;
  height: 31px;
  margin: 5px;
}

nav {
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 99;
}

h1 {
  font-family: 'Quattrocento Sans', sans-serif;
  font-style: Bold;
  font-size: 27pt;
}

textarea {
  appearance: none;
  padding: 10px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid black;
  border-radius: 3px;
}

input[type=text] {
  appearance: none;
  border: 1px solid black;
  font-size: 12px;
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;

}

input[type=submit] {
  border: none;
  background-color: black;
  color:white;
  text-transform: uppercase;
}

h2 {
  font-family: 'Quattrocento Sans', sans-serif;
  font-style: Bold;
  font-size: 27pt;
  line-height: 1.2em;
}

h3 {
  font-size: 12px;
  font-weight: 600;
}


section {
  /* scroll-snap-align: start; */
  background-color: whitesmoke;
  margin-left: auto;
  margin-right: auto;
  /* scroll-snap-align: start; */
}

