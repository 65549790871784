
.section-footer  .section-content{
    display: grid;
    /* padding: 78px 0px 46px 0px; */
    grid-template-columns: 11.1% auto;
    grid-template-rows: 1fr 1fr 1fr;
    overflow: hidden;
    row-gap: 1em;
}


