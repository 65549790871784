.section-place  .section-content{
   display: grid;
   padding: 60px 0px;
   grid-template-columns: 11.1% 37.8% 11.1% auto;
   grid-template-rows: auto 1fr 0.25fr 1fr;

}


.section-place .artifact {
    /* grid-column: 4; */
    position: absolute;
    top: calc(50% - 200px);
    opacity: 0.15;
    height: 400px;
    width: 400px;
    left: -200px; 
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size:  cover;
    background-position: 100%  50%;
    background-repeat: no-repeat;
    /* transform: rotate(40deg); */
}

.section-place  .heading{
    grid-column: 2;
    grid-row: 1;
    /* background-color: blue; */
    max-width: 400px;
    margin-top: 0px;
} 

.section-place .artifact2 {
    /* grid-column: 4; */
    position: absolute;
    opacity: 0.4;
    height: 270px;
    width: 270px;
    right: 120px;
    top: 350px;
    /* right: 100px; */
    /* bottom:0px; */
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size:  100% ;
    background-position: 100% 0px;
    background-repeat: no-repeat;
    
}

.section-place .wines-button {
    grid-row: 3;
    grid-column: 3;
}

.section-place .section-nav {
    grid-column: 1;
}


.section-place  .hero-image{
   grid-row: 1 / span2;
   grid-column: 4;
   /* background-color: crimson; */
   /* height:60%; */
   /* height: auto; */
   background-position: 40% 0;
   background-size: auto 400px;
   background-image: url("/place.jpg");
   /* background-size: cover; */
   background-repeat: no-repeat;

} 

@keyframes fadein {
   from {opacity:0%;}
   to {opacity:100%;}
 }

.section-place .text-group {
   grid-row: 2 ;
   grid-column: 2;
   width: 391px;
}

@media only screen and (orientation: portrait) and (max-width: 890px) {
    .section-place  .section-content{
        overflow: visible;
        padding: 36px 0px 30px 0px;
        grid-template-columns:  5.225% 5.225% 8% 24.5% 7.05% 7.05% 24.5% 18.45%;
        grid-template-rows: auto 1fr 0.3fr 1.5fr; 
    }

    .section-place .section-nav{
        /* margin-left: 77px; */
        justify-self: start;
        grid-row: 1;
        grid-column: 3;
    }
 
    .section-place .heading{ 
        width: auto;
        max-width: none;
        grid-column: 4 / span 4;
        max-width: 350px;
    }
    
    .section-place .text-group {
        grid-column:4 / span 4;
        width: auto;
        max-width: 350px;
    }

    .section-place  .hero-image{
        grid-column: 2 / span 7;
        grid-row: 4;

        /* background-position: calc(70% + 250px) 20px; */
        /* background-position: ; */
        background-size: 100% auto;
    } 

    .section-place .artifact {
        top:-125px;
        
    }

    .section-place .artifact2 {


    }
    
    .section-place .wines-button {
        grid-column: 4;
    }
}

@media only screen and (max-width: 530px) {

    .section-place  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-place .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 150px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-place .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-place .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-place .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-place .wines-button {
        display: none;
    }



    .section-place .artifact {
        grid-column: 3;
        width: 60%;
        margin-top: -180px;
        left: 60px;

    }

}
