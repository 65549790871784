.sectionContactAlt{
    color: white;
    background-color: rgb(3,3,3);
    background-image:url("/acquire.jpg");
    background-repeat: no-repeat;
    background-position: calc(50%  - (12.5vw - 18vh)) 0%;
    background-size: 200vh
}

.contentWrapper {
    max-width: var(--desktop-max-width);
    height: 100vh;
    display: grid;
    grid-template-columns: 50% auto;
    grid-template-columns: calc(50%  - (3vw - 5vh)) auto;
    grid-template-rows: 46% 50%;
    margin: 0 auto;
}

.info {
    justify-self: center;
    grid-row: 1 / span 2;
    grid-column: 1 ;
    display: grid;;
    grid-template-rows: 1fr 1fr 1fr ;
    width: 50%;
    align-self: center;
}


.emailGroup {
    display: grid;
    row-gap: 1.5em;
    flex-direction: column;
    grid-column: 1;
}

.phoneGroup {
    display: grid;
    row-gap: 1.5em;
    flex-direction: column;
    grid-row: 3;
}

.addressGroup {
    grid-column: 3;
}

.openGroup {
    grid-column: 4;
}


.footerLabel {
    text-transform: uppercase;
    font-weight: 600;
}

.infoGroup {
    display: grid;
    row-gap: 1.5em;
    flex-direction: column;
}

.infoGroup label {
    justify-self: center;
    align-self: center;
}