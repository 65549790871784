.section-approach  .section-content{
   position: relative;
   display: grid;
   padding: 60px 0px 100px 0px;
   grid-template-columns: 39.45% auto 11.4% 9.17% 30.37%;
   grid-template-rows: 37px auto 1fr 0.75fr auto
}

.section-approach .section-nav {
    grid-row: 2 ;
    grid-column: 2;
 }

 .section-approach  .heading{
    grid-column: 3 / span 3;
    grid-row: 2;
    width: auto;
    max-width: none;
    max-width: 400px;
    margin-top: 0px;
    margin-left: 10px;
 } 
 

.section-approach  .hero-image{
    grid-row: 1 / span 5;
    grid-column: 1;

    background-position: 70% 0%;
    background-size: auto 100%;
    background-image: url("/approach.jpg");
    background-repeat: no-repeat;
 
 } 

 .section-approach .artifact {
    position: absolute;
    opacity: 0.2;
    width: 270px; 
    height: 270px; 
    /* overflow: visible; */
    top: 45%;
    left: calc(50% - 230px);
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size:  100% ;
    background-position: 100% 0px;
    background-repeat: no-repeat;

    /* transform: rotate(40deg); */
}




@keyframes fadein {
   from {opacity:0%;}
   to {opacity:100%;}
 }

.section-approach .text-group {
   margin-left: 10px;
   grid-row: 3;
   grid-column: 3;
   width: 391px;
   /* margin-bottom: 150px; */
   /* background-color: seagreen; */
}

.section-approach .info {
   display: flex;
   flex-direction: column;
   align-content: stretch;
}

.section-approach .wines-button {
    margin-left: 10px;
    grid-column:3 / span 2;
    grid-row: 5;
    justify-self: left;
}

.section-approach .acquire-button {
    grid-column: 5;
    grid-row: 5;
}

@media only screen and (orientation: portrait) and (max-width: 890px) {


    .section-approach  .section-content{
        padding: 26px 0px 59px 0px;
        grid-template-columns: 10.45% 8% 24.5% minmax(20px, 14.1%) 24.5% 18.45%;
        /* grid-template-rows: 37px auto 1fr 6.1% 40px; */
        grid-template-rows: 37px auto 1fr 1.1fr 0.3fr; 
    }

    .section-approach .heading{ 
        grid-column: 5 / span 2;
        max-width: 242px;
    }

    .section-approach .section-nav{
        grid-column: 4;
    }

    .section-approach .text-group {
       grid-column: 5/ span 2;
       grid-row: 3;
       width: auto;
       max-width: 242px;
    }

   .section-approach  .hero-image{
       grid-row: 1 / span 4;
       grid-column: 1 / span 3 ;
       background-position: calc(106% + 350px) 0%;
       background-size: auto 104%;  
   }

   .section-approach .artifact {
        opacity: 0.15;
        left: calc(50% - 250px);
        top: calc(60% - 250px); 
        width: 500px;
        height: 500px;
    }

    .section-approach .wines-button {
        justify-self: end;
	    align-self: end;
        grid-column: 3 ;

        grid-row: 5;
        width: auto;
    }

    .section-approach .acquire-button {
	align-self: end;
        grid-column: 3 ;

        grid-row: 5;
        width: auto;
    }

    .section-approach .acquire-button {
        grid-column: 5;
        grid-column: 5;
        grid-row: 5;
    }

}


@media only screen and (max-width: 530px) {

    .section-approach  .section-content{
        padding: 9% 0px 45px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-approach .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 180px;
        font-size: 20px;
        line-height: 22px;
    }

    .section-approach .section-nav {
        grid-column: 2;
        grid-row: 1  / span 2;
    }


    .section-approach .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-approach .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-approach .wines-button {
        display: none;
    }

    .section-approach .acquire-button {
        display: none;
    }



    .section-approach .text-group .copy-text {
        margin-bottom: 0px;
        margin-block-end: 0px;
    }

    .section-approach .artifact {
        opacity: 0.2;
        width: 150px;
        height: 150px;
        top: 250px;
        left: -55px;
    }

}

