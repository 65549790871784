

.nav {
    /* background-color: black; */
}

.navLogo:hover {
    cursor: pointer;

}
.navContentWrapper {
    display: flex;
    justify-content: space-between;
}  

.navContent {
    margin: 0 auto;
    max-width: var(--desktop-max-width);
    padding:  var(--desktop-padding);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-sizing: content-box;


    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */

    /* background-color: black; */
}

.navContent a:link {
    text-decoration: none;
}

.navItems {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.navItems a {
    color: white;
    text-transform: uppercase;
    list-style: none;
    font-weight: 600;
    letter-spacing: 0.7pt;
    mix-blend-mode: difference;
}

.navItems a + a {
    margin-left: 2.5rem;
}

.navItems .navButton {
    border-color: white;
    border-style: solid;
    border-width: 1px 0px;
    height: 12px;
    width: 43px;
    margin-left: 20px;
}

.navButton:hover {
    cursor: pointer;

}

.quickMenuLabel {
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.7pt;
}


@media only screen and (min-width: 890px) {  
    .navButton {
        display: none;
    }

    .quickMenuLabel {
        display: none;
    }
} 

@media only screen and (max-width: 890px) {  
    .navItems a {
        display: none;
    }
}

@media only screen and (max-width: 530px) {  
    .navLogo {
        height: 15px;
    }

    .navContent {
        padding: 0px 25px;
    }

    .quickMenuLabel {
        display: none;
    }
}

