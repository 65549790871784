@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quattrocento+Sans:wght@700&display=swap);
body {
  /* background-color: rgb(44,44,44); */
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  background-color: rgb(16,16,16);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  scroll-behavior: smooth;
  /* scroll-snap-type: y proximity; */
  /* -webkit-overflow-scrolling: touch; */
}
/* */
@media only screen and (max-width: 890px) {
  /* body {
    scroll-snap-type: proximity;
  } */

} 

@media only screen and (max-width: 500px) {
  /*body {
    scroll-snap-type: mandatory;
  }*/

} 

.linkButton {
  text-align: center;
  text-decoration: none;
  background-color: white;
  border: none;
  font-size: 9pt;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0 0px 20px 0 rgba(0,0,0,0.2);
  width: 190px;
  height: 31px;
  margin: 5px;
}

button {
  background-color: white;
  border: none;
  font-size: 9pt;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0 0px 20px 0 rgba(0,0,0,0.2);
  width: 190px;
  height: 31px;
  margin: 5px;
}

nav {
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 99;
}

h1 {
  font-family: 'Quattrocento Sans', sans-serif;
  font-style: Bold;
  font-size: 27pt;
}

textarea {
  -webkit-appearance: none;
          appearance: none;
  padding: 10px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid black;
  border-radius: 3px;
}

input[type=text] {
  -webkit-appearance: none;
          appearance: none;
  border: 1px solid black;
  font-size: 12px;
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;

}

input[type=submit] {
  border: none;
  background-color: black;
  color:white;
  text-transform: uppercase;
}

h2 {
  font-family: 'Quattrocento Sans', sans-serif;
  font-style: Bold;
  font-size: 27pt;
  line-height: 1.2em;
}

h3 {
  font-size: 12px;
  font-weight: 600;
}


section {
  /* scroll-snap-align: start; */
  background-color: whitesmoke;
  margin-left: auto;
  margin-right: auto;
  /* scroll-snap-align: start; */
}




.Navbar_nav__1dWpI {
    /* background-color: black; */
}

.Navbar_navLogo__2js9P:hover {
    cursor: pointer;

}
.Navbar_navContentWrapper__3jBgi {
    display: flex;
    justify-content: space-between;
}  

.Navbar_navContent__1NuzZ {
    margin: 0 auto;
    max-width: var(--desktop-max-width);
    padding:  var(--desktop-padding);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-sizing: content-box;


    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */

    /* background-color: black; */
}

.Navbar_navContent__1NuzZ a:link {
    text-decoration: none;
}

.Navbar_navItems__rRRX6 {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.Navbar_navItems__rRRX6 a {
    color: white;
    text-transform: uppercase;
    list-style: none;
    font-weight: 600;
    letter-spacing: 0.7pt;
    mix-blend-mode: difference;
}

.Navbar_navItems__rRRX6 a + a {
    margin-left: 2.5rem;
}

.Navbar_navItems__rRRX6 .Navbar_navButton__15igS {
    border-color: white;
    border-style: solid;
    border-width: 1px 0px;
    height: 12px;
    width: 43px;
    margin-left: 20px;
}

.Navbar_navButton__15igS:hover {
    cursor: pointer;

}

.Navbar_quickMenuLabel__12__u {
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.7pt;
}


@media only screen and (min-width: 890px) {  
    .Navbar_navButton__15igS {
        display: none;
    }

    .Navbar_quickMenuLabel__12__u {
        display: none;
    }
} 

@media only screen and (max-width: 890px) {  
    .Navbar_navItems__rRRX6 a {
        display: none;
    }
}

@media only screen and (max-width: 530px) {  
    .Navbar_navLogo__2js9P {
        height: 15px;
    }

    .Navbar_navContent__1NuzZ {
        padding: 0px 25px;
    }

    .Navbar_quickMenuLabel__12__u {
        display: none;
    }
}


.QuickMenu_quickMenu__16nH8{
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    width: 57.5vw;
    height: 100vh;
    opacity: 0;
    background-color: black;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.801);
    -webkit-transform: translate(70vw, 0);
            transform: translate(70vw, 0);
    transition-property: -webkit-transform opacity;
    transition-property: transform opacity;
    transition-property: transform opacity, -webkit-transform opacity;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;

}
.QuickMenu_navItemsWrapper__2c3Rz {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: var(--desktop-padding);
}

.QuickMenu_quickMenuExpanded__1-CO0{
    opacity: 100;
    -webkit-transform: translate(0vw, 0);
            transform: translate(0vw, 0);
}



.QuickMenu_navItems__1KhQW {
    margin-top: 80px;
}


.QuickMenu_navItems__1KhQW a {
    display: flex;
    font-size: 16px;
    font-family: "Open Sans";
    justify-content: space-between;
    color: white;
    margin-top: 2rem;
    list-style: none;
    text-decoration: none;
}

.QuickMenu_quickMenuLabel__Nv9PO {
    text-transform: uppercase;
    display: block;
    color: white;
    font-size: 13px;
    margin-left: 2.2rem;
    margin-top: 1.5rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: left;
            transform-origin: left;
}

.QuickMenu_closeButton__1xnfN {
    background-image: url(/static/media/close.4203e966.svg);
    background-repeat: no-repeat;
    height: 61px;
    width: 61px;
    top: 0;
    right: 0;
    margin-top: 12px;
    margin-right: 0px;
    cursor: pointer;
    position: absolute;
 } 





/* .navItems a > .active {
    font-style: italic;
} */

/* .navItemExpanded > .active li{
    list-style-image: url('minus.svg');
} */




/* .nav-button-expanded:hover {
    cursor: pointer;
} */

/* .quick-menu {
    display: none;
} */

/* .quick-menu-expanded {
    text-transform: uppercase;
    display: block;
    color: white;
    font-size: 13px;
    margin-left: 2.2rem;
    margin-top: 1.5rem;
    transform: rotate(-90deg);
    transform-origin: left;
} */

    /* .quick-menu {
        display: block;
        color: white;
        text-transform: uppercase;
        margin-right: 1rem;
        font-weight: 600;
        font-size:10pt;
    } */

/* 
    @media only screen and (max-height: 488px) {  

        .nav-item-expanded{
            margin-top: 20px;
            
        }
        .quick-menu-expanded{
            transform: none;
            display: none;
            
        }
    
        .nav-button-expanded{
            position: fixed;
            right: 0.5rem;
        }
    } */
.Home_sectionHome__2ugU9 {
    color: white;
    background-color: rgb(3,3,3);
    background-image:url("/hero.jpg");
    background-repeat: no-repeat;
    background-position: calc(50%  - (12.5vw - 18vh)) 0%;
    background-size: 200vh

}

.Home_contentWrapper__2K3Ql {
    max-width: var(--desktop-max-width);
    /* max-height: var(--desktop-max-height); */
    /* min-height: var(--desktop-max-height); */
    height: 100vh;
    /* padding:  var(--desktop-padding); */
    display: grid;
    grid-template-columns: 50% auto;
    grid-template-columns: calc(50%  - (3vw - 5vh)) auto;
    grid-template-rows: 46% 50%;
    margin: 0 auto;
}

.Home_hero__2-BIP {
    grid-row: 2;
    grid-column: 2;
    max-width: 75.4%;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    /* .sectionHome {
        background-position: 41.7% 0%;
    } */
    
    .Home_contentWrapper__2K3Ql {
        /* grid-template-columns: 56.8% auto; */
        grid-template-columns: 10.45% 8% 24.5% minmax(20px, 14.1%) 24.5% 18.45%;
        grid-template-rows: 45% auto;
    }

    .Home_hero__2-BIP {
        grid-column: 5 / span 2;
        margin-top: 1rem;
        max-width: 75%;
    }
    
}


@media only screen and (max-width: 530px) {
    .Home_sectionHome__2ugU9 {
        background-position: 42% 0%;
        background-size: 188vh;
    }

    .Home_contentWrapper__2K3Ql {
        grid-template-columns: 47% auto;
        grid-template-rows: 40% auto;
        max-width: 100%;
        height: calc(1vh * 100);
        height: calc(var(--vh, 1vh) * 100);
    }

    .Home_hero__2-BIP {
        margin-top: 1rem;
        grid-row: 2;
        grid-column: 4;
        max-width: 100%;
    }

    .Home_copyHeading__1yp5D {
        font-size: 16pt;
        margin-bottom: 1rem;
    }
    
    .Home_copyText__3DwQo {
        font-size: 9pt;
    }
}

@media only screen and (max-height: 650px) {
    .Home_contentWrapper__2K3Ql {
        grid-template-rows: auto;
    }

    /* .copyText {
        display: none;
    } */
}


/* @media only screen and (orientation: landscape) and (max-height: 660px) {
    .csection-contnet-hero {
        grid-template-columns: auto 45%;
        grid-template-rows: 30% auto;
    }

    .section-home .hero {
        margin-top: 1rem;
        grid-row: 2;
        grid-column: 4;
    }
    

    .section-home {
        background-position: 50% 0%;
        background-size: 188vh;
        max-width: 100%;
    }

    .section-home .copy-heading {
        font-size: 16pt;
        margin-bottom: 1rem;
    }
    
    .section-home .copy-text {
        font-size: 9pt;
    }
}  */

@-webkit-keyframes fadein {
    from {opacity:0%;}
    to {opacity:100%;}
  }

@keyframes fadein {
    from {opacity:0%;}
    to {opacity:100%;}
  }

.panel {
    display: none;
    -webkit-animation-name: fadein;
            animation-name: fadein;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
}

.panel-expanded {
   display: block;
}


.showMoreButton {
    background-color: transparent;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    box-shadow: none;
    border-color: black;
    width: auto;
    height: auto;
    padding: 0px;
    letter-spacing: 1.4px;
    font-size: 10px;
    margin: 20px 0px;
    opacity: 100;
    transition: opacity 0.5s ease-in-out;
    max-height: none;
    cursor: pointer;
}

.showMoreButton-expanded {
   display: none;
}

.section-content-wrapper {

}

.section-story  .section-content{
    display: grid;
    padding: 78px 0px 46px 0px;
    grid-template-columns: 11.1% 37.8% 11.1% auto;
    grid-template-rows: auto 1fr 0.25fr auto;
}

.section-story .section-nav{
    grid-column: 1;
    grid-row: 1;
    margin-top: -0.9ch;
}

.section-story  .heading{
    grid-column: 2;
    grid-row: 1;
    max-width: 200px;
    margin-top: -0.45em;
} 

.section-story  .hero-image{
    grid-row: 1 / span 2;
    grid-column: 4;
    background-size: 100% auto;
    background-image: url("/story.jpg");
    background-repeat: no-repeat;
} 

.section-story .text-group {
    grid-row: 2;
    grid-column: 2;
    /* margin-bottom: 87px; */
}

.section-story .wines-button {
    grid-row: 4;
    grid-column: 2;
}

.section-story .artifact {
    position: absolute; 
    opacity: 0.4;
    width: 280px; 
    height: 280px; 
    left: 50%;
    top: 40%;
    overflow: visible;
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    
    .section-story  .section-content{
        padding: 60px 0px 75px 0px;
        grid-template-columns: 10.45% 8% 24.5% 7.05% 7.05% 24.5% 18.45%;
        grid-template-rows: auto 3fr 1fr auto
    }

    .section-story .section-nav{
        /* margin-left: 77px; */
        justify-self: start;
        grid-row: 1;
        grid-column: 2;
    }

    .section-story .heading{ 
        width: auto;
        grid-column: 3;
	    margin-bottom: 30px;
    }
    
    .section-story .text-group {
        grid-column:3 / span 2;
        width: auto;
    }

    .section-story  .hero-image{
        grid-column: 6 / span 2;
        background-position: calc(70% + 250px) 0px;
        background-size: auto 492px ;  
    } 

    .section-story .artifact {
        width: 270px;
        height: 270px;
        left: 60%;
        top:50%;
    }
    
    .section-story .wines-button {
        grid-column: 3;
    }
}


@media only screen and (max-height: 400px) {
    .section-story .wines-button {
        display: none;
    }
}

/* Mobile */
@media only screen and (max-width: 530px) {

    .section-story  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-story .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 100px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-story .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-story .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-story .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-story .wines-button {
        display: none;
    }



    .section-story .artifact {
        width: 150px;
        height: 150px;
        top: 250px;
        left:50%;
    }
}

.section-place  .section-content{
   display: grid;
   padding: 60px 0px;
   grid-template-columns: 11.1% 37.8% 11.1% auto;
   grid-template-rows: auto 1fr 0.25fr 1fr;

}


.section-place .artifact {
    /* grid-column: 4; */
    position: absolute;
    top: calc(50% - 200px);
    opacity: 0.15;
    height: 400px;
    width: 400px;
    left: -200px; 
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size:  cover;
    background-position: 100%  50%;
    background-repeat: no-repeat;
    /* transform: rotate(40deg); */
}

.section-place  .heading{
    grid-column: 2;
    grid-row: 1;
    /* background-color: blue; */
    max-width: 400px;
    margin-top: 0px;
} 

.section-place .artifact2 {
    /* grid-column: 4; */
    position: absolute;
    opacity: 0.4;
    height: 270px;
    width: 270px;
    right: 120px;
    top: 350px;
    /* right: 100px; */
    /* bottom:0px; */
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size:  100% ;
    background-position: 100% 0px;
    background-repeat: no-repeat;
    
}

.section-place .wines-button {
    grid-row: 3;
    grid-column: 3;
}

.section-place .section-nav {
    grid-column: 1;
}


.section-place  .hero-image{
   grid-row: 1 / span2;
   grid-column: 4;
   /* background-color: crimson; */
   /* height:60%; */
   /* height: auto; */
   background-position: 40% 0;
   background-size: auto 400px;
   background-image: url("/place.jpg");
   /* background-size: cover; */
   background-repeat: no-repeat;

} 

@-webkit-keyframes fadein {
   from {opacity:0%;}
   to {opacity:100%;}
 } 

@keyframes fadein {
   from {opacity:0%;}
   to {opacity:100%;}
 }

.section-place .text-group {
   grid-row: 2 ;
   grid-column: 2;
   width: 391px;
}

@media only screen and (orientation: portrait) and (max-width: 890px) {
    .section-place  .section-content{
        overflow: visible;
        padding: 36px 0px 30px 0px;
        grid-template-columns:  5.225% 5.225% 8% 24.5% 7.05% 7.05% 24.5% 18.45%;
        grid-template-rows: auto 1fr 0.3fr 1.5fr; 
    }

    .section-place .section-nav{
        /* margin-left: 77px; */
        justify-self: start;
        grid-row: 1;
        grid-column: 3;
    }
 
    .section-place .heading{ 
        width: auto;
        max-width: none;
        grid-column: 4 / span 4;
        max-width: 350px;
    }
    
    .section-place .text-group {
        grid-column:4 / span 4;
        width: auto;
        max-width: 350px;
    }

    .section-place  .hero-image{
        grid-column: 2 / span 7;
        grid-row: 4;

        /* background-position: calc(70% + 250px) 20px; */
        /* background-position: ; */
        background-size: 100% auto;
    } 

    .section-place .artifact {
        top:-125px;
        
    }

    .section-place .artifact2 {


    }
    
    .section-place .wines-button {
        grid-column: 4;
    }
}

@media only screen and (max-width: 530px) {

    .section-place  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-place .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 150px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-place .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-place .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-place .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-place .wines-button {
        display: none;
    }



    .section-place .artifact {
        grid-column: 3;
        width: 60%;
        margin-top: -180px;
        left: 60px;

    }

}

.section-wines {
    background-color: rgb(16,16,16);
    background-repeat: no-repeat;
    background-position: center 0px;
    background-size: auto 100%;
    /* scroll-snap-align: start; */
    /* scroll-snap-stop: always;; */
}


.malva {
    background-image:url("/white.png");
}

.teran {
    background-image:url("/black.png");
}

.visible {
    opacity: 1.0;
}

.section-wines .section-content-wrapper {
    background-color: transparent;
}


.section-wines  .section-content{
    height: 100vh;
  
    display: grid;
    grid-template-columns: 11.1% 18.9% 9.45% 9.45% auto 9.45% 9.45%  18.9%;
    grid-template-rows: 26% 1fr auto 2fr;
}

.section-wines .malva-description{
    margin-top: 0px;
}


.section-wines .teran-description{
    margin-top: 0px;
}



.section-wines .heading {
    grid-column: 1 / span 8;
    justify-self: center;
    z-index: 2;
}

.section-wines .malva-heading {
    grid-column: 3;
    z-index: 2;
}

.section-wines .teran-heading {
    grid-column: 6;
    grid-row: 2;
    margin-bottom: 20px;
}


.section-wines .text-group-malva{
    grid-column: 2;
    text-align: right;
    /* max-width: %; */
    justify-self: right;
    z-index: 2;
}

.section-wines .text-group-teran{
    grid-column: 7 / span 2;
    max-width: 60%;
    z-index: 2;

}

.section-wines .blured{
    color: transparent;
    text-shadow: 0 0 5px rgba(255,255,255,0.5);
    transition: color 0.2s ease-in-out;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    .section-wines  .section-content{
        padding: 48px 0px 75px 0px;
        grid-template-columns: 10.45% 8% 12.25% 12.25% 7.05% 7.05% 12.25% 12.25% 18.45%;
        grid-template-rows: 18% 0.1fr 0.1fr auto
    }

    .section-wines .heading {
        grid-column: 5 / span 2;
        justify-self: center;
    }
    
    .section-wines .malva-heading {
        grid-column: 3;
        justify-self: end;
        margin-bottom: 40px;
    }
    
    .section-wines .teran-heading {
        grid-column: 8;
        grid-row: 3;
        justify-self: start;
        align-self: flex-end;
        margin-bottom: 0px;
    }

    .section-wines .malva-specs {
        margin-bottom: 0px;
    }



    
    .section-wines .text-group-malva{
        grid-column: 1 / span 3;
        text-align: right;
        /* max-width: %; */
        justify-self: right;
        grid-row: 3;
        max-width: 75%;
    }
    
    .section-wines .text-group-teran{
        grid-column: 8 / span 2;
        grid-row: 4;
        margin-top: 40px;
        max-width: 75%;
    }

}

/* Mobile */
@media only screen and (max-width: 530px) {
    .section-content-wrapper {

     }

    .section-wines  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-wines .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 100px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-wines .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-wines .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-wines .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-wines .wines-button {
        display: none;
    }

    .section-wines .artifact {
        grid-column: 3;
        width: 60%;
        margin-top: -180px;
        left: 60px;

    }
}
.section-approach  .section-content{
   position: relative;
   display: grid;
   padding: 60px 0px 100px 0px;
   grid-template-columns: 39.45% auto 11.4% 9.17% 30.37%;
   grid-template-rows: 37px auto 1fr 0.75fr auto
}

.section-approach .section-nav {
    grid-row: 2 ;
    grid-column: 2;
 }

 .section-approach  .heading{
    grid-column: 3 / span 3;
    grid-row: 2;
    width: auto;
    max-width: none;
    max-width: 400px;
    margin-top: 0px;
    margin-left: 10px;
 } 
 

.section-approach  .hero-image{
    grid-row: 1 / span 5;
    grid-column: 1;

    background-position: 70% 0%;
    background-size: auto 100%;
    background-image: url("/approach.jpg");
    background-repeat: no-repeat;
 
 } 

 .section-approach .artifact {
    position: absolute;
    opacity: 0.2;
    width: 270px; 
    height: 270px; 
    /* overflow: visible; */
    top: 45%;
    left: calc(50% - 230px);
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size:  100% ;
    background-position: 100% 0px;
    background-repeat: no-repeat;

    /* transform: rotate(40deg); */
}




@-webkit-keyframes fadein {
   from {opacity:0%;}
   to {opacity:100%;}
 }




@keyframes fadein {
   from {opacity:0%;}
   to {opacity:100%;}
 }

.section-approach .text-group {
   margin-left: 10px;
   grid-row: 3;
   grid-column: 3;
   width: 391px;
   /* margin-bottom: 150px; */
   /* background-color: seagreen; */
}

.section-approach .info {
   display: flex;
   flex-direction: column;
   align-content: stretch;
}

.section-approach .wines-button {
    margin-left: 10px;
    grid-column:3 / span 2;
    grid-row: 5;
    justify-self: left;
}

.section-approach .acquire-button {
    grid-column: 5;
    grid-row: 5;
}

@media only screen and (orientation: portrait) and (max-width: 890px) {


    .section-approach  .section-content{
        padding: 26px 0px 59px 0px;
        grid-template-columns: 10.45% 8% 24.5% minmax(20px, 14.1%) 24.5% 18.45%;
        /* grid-template-rows: 37px auto 1fr 6.1% 40px; */
        grid-template-rows: 37px auto 1fr 1.1fr 0.3fr; 
    }

    .section-approach .heading{ 
        grid-column: 5 / span 2;
        max-width: 242px;
    }

    .section-approach .section-nav{
        grid-column: 4;
    }

    .section-approach .text-group {
       grid-column: 5/ span 2;
       grid-row: 3;
       width: auto;
       max-width: 242px;
    }

   .section-approach  .hero-image{
       grid-row: 1 / span 4;
       grid-column: 1 / span 3 ;
       background-position: calc(106% + 350px) 0%;
       background-size: auto 104%;  
   }

   .section-approach .artifact {
        opacity: 0.15;
        left: calc(50% - 250px);
        top: calc(60% - 250px); 
        width: 500px;
        height: 500px;
    }

    .section-approach .wines-button {
        justify-self: end;
	    align-self: end;
        grid-column: 3 ;

        grid-row: 5;
        width: auto;
    }

    .section-approach .acquire-button {
	align-self: end;
        grid-column: 3 ;

        grid-row: 5;
        width: auto;
    }

    .section-approach .acquire-button {
        grid-column: 5;
        grid-column: 5;
        grid-row: 5;
    }

}


@media only screen and (max-width: 530px) {

    .section-approach  .section-content{
        padding: 9% 0px 45px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-approach .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 180px;
        font-size: 20px;
        line-height: 22px;
    }

    .section-approach .section-nav {
        grid-column: 2;
        grid-row: 1  / span 2;
    }


    .section-approach .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-approach .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-approach .wines-button {
        display: none;
    }

    .section-approach .acquire-button {
        display: none;
    }



    .section-approach .text-group .copy-text {
        margin-bottom: 0px;
        -webkit-margin-after: 0px;
                margin-block-end: 0px;
    }

    .section-approach .artifact {
        opacity: 0.2;
        width: 150px;
        height: 150px;
        top: 250px;
        left: -55px;
    }

}


.ContactAlt_sectionContactAlt__3q4hu{
    color: white;
    background-color: rgb(3,3,3);
    background-image:url("/acquire.jpg");
    background-repeat: no-repeat;
    background-position: calc(50%  - (12.5vw - 18vh)) 0%;
    background-size: 200vh
}

.ContactAlt_contentWrapper__1uECd {
    max-width: var(--desktop-max-width);
    height: 100vh;
    display: grid;
    grid-template-columns: 50% auto;
    grid-template-columns: calc(50%  - (3vw - 5vh)) auto;
    grid-template-rows: 46% 50%;
    margin: 0 auto;
}

.ContactAlt_info__3EHZL {
    justify-self: center;
    grid-row: 1 / span 2;
    grid-column: 1 ;
    display: grid;;
    grid-template-rows: 1fr 1fr 1fr ;
    width: 50%;
    align-self: center;
}


.ContactAlt_emailGroup__1OLqg {
    display: grid;
    grid-row-gap: 1.5em;
    row-gap: 1.5em;
    flex-direction: column;
    grid-column: 1;
}

.ContactAlt_phoneGroup__25PVP {
    display: grid;
    grid-row-gap: 1.5em;
    row-gap: 1.5em;
    flex-direction: column;
    grid-row: 3;
}

.ContactAlt_addressGroup__2NPyP {
    grid-column: 3;
}

.ContactAlt_openGroup__3wwfV {
    grid-column: 4;
}


.ContactAlt_footerLabel__2X957 {
    text-transform: uppercase;
    font-weight: 600;
}

.ContactAlt_infoGroup__GVC2n {
    display: grid;
    grid-row-gap: 1.5em;
    row-gap: 1.5em;
    flex-direction: column;
}

.ContactAlt_infoGroup__GVC2n label {
    justify-self: center;
    align-self: center;
}

.section-acquire{
    background-color: rgb(18,18,18);
    background-repeat: no-repeat;
    background-position: 50% 0px;
    background-size: auto 100%;
    background-image:url("/acquire.jpg");
}

.section-acquire .section-content-wrapper{
    background-color: transparent;
}


.section-acquire  .section-content{
    height: 100vh;
    display: grid;
    padding: 78px 0px 46px 0px;
    grid-template-columns: 11.1% 37.8% 11.1% auto;
    grid-template-rows: auto 1fr 0.25fr 2fr;
}

.section-acquire .section-nav{
    grid-column: 1;
    grid-row: 1;
    margin-top: -0.9ch;
}

.section-acquire  .heading{
    grid-column: 2;
    grid-row: 1;
    max-width: 200px;
    margin-top: -0.45em;
} 

/* .section-acquire  .hero-image{
    grid-row: 1 / span 2;
    grid-column: 4;
    background-size: 100% auto;
    background-image: url("acquire.jpg");
    background-repeat: no-repeat;
}  */

.section-acquire .text-group {
    grid-row: 2;
    grid-column: 2;
    /* margin-bottom: 87px; */
}

.section-acquire .wines-button {
    grid-row: 4;
    grid-column: 2;
}

.section-acquire .artifact {
    position: relative; 
    grid-row: 1 / span 3; 
    grid-column: 1 / span 4; 
    opacity: 48%;
    width: 200px; 
    height: 200px; 
    left: 50%;
    top: 40%;
    overflow: visible;
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    
    .section-acquire  .section-content{
        padding: 60px 0px 75px 0px;
        grid-template-columns: 10.45% 8% 24.5% 7.05% 7.05% 24.5% 18.45%;
        grid-template-rows: auto 3fr 1fr auto
    }

    .section-acquire .section-nav{
        /* margin-left: 77px; */
        justify-self: start;
        grid-row: 1;
        grid-column: 2;
    }

    .section-acquire .heading{ 
        width: auto;
        grid-column: 3;
	    margin-bottom: 30px;
    }
    
    .section-acquire .text-group {
        grid-column:3 / span 2;
        width: auto;
    }

    .section-acquire  .hero-image{
        grid-column: 6 / span 2;
        background-position: calc(70% + 250px) 0px;
        background-size: auto 492px ;  
    } 

    .section-acquire .artifact {
        grid-column: 6 / span 2;
        grid-row: 2;
        left:0px;
        opacity: 40%;
        top: 250px;
        width: 100%;
        height: 270px;

    }
    
    .section-acquire .wines-button {
        grid-column: 3;
    }
}


@media only screen and (max-height: 400px) {
    .section-acquire .wines-button {
        display: none;
    }
}

/* Mobile */
@media only screen and (max-width: 530px) {
    .section-content-wrapper {
	/* height: 100vh; */
     }
    .section-acquire  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-acquire .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 100px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-acquire .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-acquire .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-acquire .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-acquire .wines-button {
        display: none;
    }



    .section-acquire .artifact {
        grid-column: 3;
        width: 60%;
        margin-top: -180px;
        left: 60px;

    }
}

#languageButton {
    position: relative;
    grid-column: 3;
    grid-row: 2;
    height: auto;
    border-radius: 50%;
    background-color: black;
    color: white;
    width: auto;
    bottom: -50%;
}

#emailGroup {
    grid-column: 1;
}

#phoneGroup {
    grid-column: 2;
}

#addressGroup {
    grid-column: 3;
}

#openGroup {
    grid-column: 4;
}

#info {
    justify-self: center;
    grid-row: 2;
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 80%;
    align-self: center;
}

.footerLabel {
    text-transform: uppercase;
    font-weight: 600;
}

.infoGroup {
    display: grid;
    grid-row-gap: 1.5em;
    row-gap: 1.5em;
    flex-direction: column;
}

.infoGroup label {
    justify-self: center;
    align-self: center;
}

#artifact {
    position: absolute; 
    opacity: 0.4;
    /* bottom: calc(-50% - 100px); */
    top: -37px;
    opacity: 0.4;
    height: 400px;
    width: 400px;
    left: 50%; 
    overflow: visible;
    background-image: url("/PANJOKA-Circles-2x-6.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.section-contact{
    height: 100vh;
}

.section-contact .section-content{
    height: 100%;
}

.section-contact .section-content-wrapper{
    height: 100%;
}

.section-contact  .section-content{
    display: grid;
    padding: 78px 0px 46px 0px;
    grid-template-columns: 11.1% auto;
    /* height: 100vh; */
}

.section-contact .section-nav{
    grid-column: 1;
    grid-row: 1;
    margin-top: -0.9ch;
}

#contactForm {
    justify-self: center;
    grid-row: 1;
    grid-column: 1 / span 2;
    width: 60%;
    height: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3em 3em auto 3em;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
}

#firstName {
    grid-column: 1;
    grid-row: 1;
}

#lastName {
    grid-column: 2;
    grid-row: 1
}

#email {
    grid-column: 1 / span 2;
    grid-row: 2;
}

#message {
    resize: none;
    grid-column: 1 / span 2;
    grid-row: 3;
}

#send {
    grid-row: 4;
    grid-column: 2;
    width: 65%;
    justify-self: right;
    margin-right: 2px;
}



@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    
    .section-contact  .section-content{
        padding: 60px 0px 75px 0px;
        grid-template-columns: 10.45% 8% 24.5% 7.05% 7.05% 24.5% 18.45%;
        grid-template-rows: auto 3fr 1fr auto
    }

    .section-contact .section-nav{
        /* margin-left: 77px; */
        justify-self: start;
        grid-row: 1;
        grid-column: 2;
    }

    .section-contact .heading{ 
        width: auto;
        grid-column: 3;
	    margin-bottom: 30px;
    }
    
    .section-contact .text-group {
        grid-column:3 / span 2;
        width: auto;
    }

    .section-contact  .hero-image{
        grid-column: 6 / span 2;
        background-position: calc(70% + 250px) 0px;
        background-size: auto 492px ;  
    } 

    .section-contact .artifact {
        grid-column: 6 / span 2;
        grid-row: 2;
        left:0px;
        opacity: 40%;
        top: 250px;
        width: 100%;
        height: 270px;

    }
    
    .section-contact .wines-button {
        grid-column: 3;
    }
}


@media only screen and (max-height: 400px) {
    .section-contact .wines-button {
        display: none;
    }
}

/* Mobile */
@media only screen and (max-width: 530px) {
    .section-content-wrapper {
	/* height: 100vh; */
     }
    .section-contact  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-contact .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 100px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-contact .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-contact .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-contact .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-contact .wines-button {
        display: none;
    }



    .section-contact .artifact {
        grid-column: 3;
        width: 60%;
        margin-top: -180px;
        left: 60px;

    }
}


.section-footer  .section-content{
    display: grid;
    /* padding: 78px 0px 46px 0px; */
    grid-template-columns: 11.1% auto;
    grid-template-rows: 1fr 1fr 1fr;
    overflow: hidden;
    grid-row-gap: 1em;
    row-gap: 1em;
}



:root {
  --desktop-max-width: 1324px;
  --desktop-max-height:800px;
  --desktop-padding: 0 22px;
}

.section-content-wrapper {
  background-color: white;
  max-width: 1324px;
  max-width: var(--desktop-max-width);
  margin-left: auto;
  margin-right: auto;

}

.copy-text {
  margin-bottom: 20px;
  margin-top: 0px;
}

.section-content {
  overflow-x: hidden;
  display: grid;
  position: relative;
}


.section-nav {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px; 
  text-transform: uppercase;
  letter-spacing: 0.7pt;
  -webkit-text-orientation: mixed;
          text-orientation: mixed;
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;

  align-self: start;
  justify-self: center;
}

.flippedY {
  -webkit-transform: scaleY(-1) scaleX(-1);
          transform: scaleY(-1) scaleX(-1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}


.dark {
  color: white;
}


@media only screen and (orientation: portrait) and (max-width: 890px) {  
  .section-content-wrapper {
     /* height: 100vh; */
  }

  /* .section-content {
    height: 100vh;
  } */
}


@media only screen and (max-width: 890px) {  
  :root {
    --desktop-padding: 0 40px;
  }
}

