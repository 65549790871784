.section-wines {
    background-color: rgb(16,16,16);
    background-repeat: no-repeat;
    background-position: center 0px;
    background-size: auto 100%;
    /* scroll-snap-align: start; */
    /* scroll-snap-stop: always;; */
}


.malva {
    background-image:url("/white.png");
}

.teran {
    background-image:url("/black.png");
}

.visible {
    opacity: 1.0;
}

.section-wines .section-content-wrapper {
    background-color: transparent;
}


.section-wines  .section-content{
    height: 100vh;
  
    display: grid;
    grid-template-columns: 11.1% 18.9% 9.45% 9.45% auto 9.45% 9.45%  18.9%;
    grid-template-rows: 26% 1fr auto 2fr;
}

.section-wines .malva-description{
    margin-top: 0px;
}


.section-wines .teran-description{
    margin-top: 0px;
}



.section-wines .heading {
    grid-column: 1 / span 8;
    justify-self: center;
    z-index: 2;
}

.section-wines .malva-heading {
    grid-column: 3;
    z-index: 2;
}

.section-wines .teran-heading {
    grid-column: 6;
    grid-row: 2;
    margin-bottom: 20px;
}


.section-wines .text-group-malva{
    grid-column: 2;
    text-align: right;
    /* max-width: %; */
    justify-self: right;
    z-index: 2;
}

.section-wines .text-group-teran{
    grid-column: 7 / span 2;
    max-width: 60%;
    z-index: 2;

}

.section-wines .blured{
    color: transparent;
    text-shadow: 0 0 5px rgba(255,255,255,0.5);
    transition: color 0.2s ease-in-out;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    .section-wines  .section-content{
        padding: 48px 0px 75px 0px;
        grid-template-columns: 10.45% 8% 12.25% 12.25% 7.05% 7.05% 12.25% 12.25% 18.45%;
        grid-template-rows: 18% 0.1fr 0.1fr auto
    }

    .section-wines .heading {
        grid-column: 5 / span 2;
        justify-self: center;
    }
    
    .section-wines .malva-heading {
        grid-column: 3;
        justify-self: end;
        margin-bottom: 40px;
    }
    
    .section-wines .teran-heading {
        grid-column: 8;
        grid-row: 3;
        justify-self: start;
        align-self: flex-end;
        margin-bottom: 0px;
    }

    .section-wines .malva-specs {
        margin-bottom: 0px;
    }



    
    .section-wines .text-group-malva{
        grid-column: 1 / span 3;
        text-align: right;
        /* max-width: %; */
        justify-self: right;
        grid-row: 3;
        max-width: 75%;
    }
    
    .section-wines .text-group-teran{
        grid-column: 8 / span 2;
        grid-row: 4;
        margin-top: 40px;
        max-width: 75%;
    }

}

/* Mobile */
@media only screen and (max-width: 530px) {
    .section-content-wrapper {

     }

    .section-wines  .section-content{
        padding: 26px 0px 46px 0px;
        grid-template-columns: 7.1% 10% 82.9%;
        grid-template-rows:  auto 208.47px 3fr;
    }
    .section-wines .heading {
        grid-column: 3;
        grid-row: 1;
        max-width: 100px;
        font-size: 20px;
        line-height: 22px;
        margin: 0px;
        
    }

    .section-wines .section-nav {
        grid-column: 2;
        grid-row: 1 ;
        margin: 0px;
    }


    .section-wines .hero-image {
        grid-row:2;
        grid-column: 3 ;
        background-position: 0% 0%;
        background-size: auto 100%;
    }


    .section-wines .text-group {
        margin-bottom: 0px;
        margin-top:20px;
        grid-row:3;
        grid-column: 2 / span 2;
        max-width: 80%;
    }

    .section-wines .wines-button {
        display: none;
    }

    .section-wines .artifact {
        grid-column: 3;
        width: 60%;
        margin-top: -180px;
        left: 60px;

    }
}