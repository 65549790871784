@import "https://fonts.googleapis.com/css2?family=Quattrocento+Sans:wght@700&display=swap";


.sectionHome {
    color: white;
    background-color: rgb(3,3,3);
    background-image:url("/hero.jpg");
    background-repeat: no-repeat;
    background-position: calc(50%  - (12.5vw - 18vh)) 0%;
    background-size: 200vh

}

.contentWrapper {
    max-width: var(--desktop-max-width);
    /* max-height: var(--desktop-max-height); */
    /* min-height: var(--desktop-max-height); */
    height: 100vh;
    /* padding:  var(--desktop-padding); */
    display: grid;
    grid-template-columns: 50% auto;
    grid-template-columns: calc(50%  - (3vw - 5vh)) auto;
    grid-template-rows: 46% 50%;
    margin: 0 auto;
}

.hero {
    grid-row: 2;
    grid-column: 2;
    max-width: 75.4%;
}


@media only screen  and (orientation: Portrait) and (max-width: 890px) {
    /* .sectionHome {
        background-position: 41.7% 0%;
    } */
    
    .contentWrapper {
        /* grid-template-columns: 56.8% auto; */
        grid-template-columns: 10.45% 8% 24.5% minmax(20px, 14.1%) 24.5% 18.45%;
        grid-template-rows: 45% auto;
    }

    .hero {
        grid-column: 5 / span 2;
        margin-top: 1rem;
        max-width: 75%;
    }
    
}


@media only screen and (max-width: 530px) {
    .sectionHome {
        background-position: 42% 0%;
        background-size: 188vh;
    }

    .contentWrapper {
        grid-template-columns: 47% auto;
        grid-template-rows: 40% auto;
        max-width: 100%;
        height: calc(var(--vh, 1vh) * 100);
    }

    .hero {
        margin-top: 1rem;
        grid-row: 2;
        grid-column: 4;
        max-width: 100%;
    }

    .copyHeading {
        font-size: 16pt;
        margin-bottom: 1rem;
    }
    
    .copyText {
        font-size: 9pt;
    }
}

@media only screen and (max-height: 650px) {
    .contentWrapper {
        grid-template-rows: auto;
    }

    /* .copyText {
        display: none;
    } */
}


/* @media only screen and (orientation: landscape) and (max-height: 660px) {
    .csection-contnet-hero {
        grid-template-columns: auto 45%;
        grid-template-rows: 30% auto;
    }

    .section-home .hero {
        margin-top: 1rem;
        grid-row: 2;
        grid-column: 4;
    }
    

    .section-home {
        background-position: 50% 0%;
        background-size: 188vh;
        max-width: 100%;
    }

    .section-home .copy-heading {
        font-size: 16pt;
        margin-bottom: 1rem;
    }
    
    .section-home .copy-text {
        font-size: 9pt;
    }
}  */
